import * as React from 'react';
import Helmet from 'react-helmet';

import { Segment } from 'components/segment/Segment';
import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';
import { Paragraph } from 'components/text/Paragraph';

export default () => (
  <>
    <Helmet title="404 Not Found" />
 
    <Intro>
      <strong>Not Found</strong><br />
      <Paragraph>
        Unable to find the requested service.
      </Paragraph><br />
      <a class="btn primary small" href="/" style={{'marginLeft': 0}}>Continue</a>
    </Intro>
 
  </>
);
